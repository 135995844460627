import { useRecoilState, useSetRecoilState } from 'recoil';
import {
  addDatabaseRedashAtom,
  allDatabasesAtom,
  curConversationAtom,
  // curDatabaseAtom,
  featureAtom,
  featureExpandAtom,
} from '../../../../recoil/atoms/atoms';
import { useEffect, useState } from 'react';
import { getAllDatabases } from '../../../../apis/datasources/apis';
import { FeatureOption } from '../../navigation';
import {
  ConversationType,
  CreateConversationDto,
} from '../../../../apis/conversations/interfaces';
import { startNewConversation } from '../../../../apis/conversations/apis';
import { fetchConversationWithId } from '../../../../utils/conversation_utils';
import Swal from 'sweetalert2';

export const ConversationalBI = () => {
  const setOption = useSetRecoilState(featureAtom);
  const setCurConversation = useSetRecoilState(curConversationAtom);
  // const [curDatabase, setCurDatabase] = useRecoilState(curDatabaseAtom);
  const [selectedDatabases, setSelectedDatabases] = useState([]);
  const [title, setTitle] = useState('');
  const [allDatabases, setAllDatabases] = useRecoilState(allDatabasesAtom);
  const [isSelectingDb, setIsSelectingDb] = useState(false);
  const setAddDatabaseModal = useSetRecoilState(addDatabaseRedashAtom);

  const setExpand = useSetRecoilState(featureExpandAtom);
  const setFeatureOption = useSetRecoilState(featureAtom);

  useEffect(() => {
    getAllDatabases().then(setAllDatabases);
    setExpand(true);

    const handleClickOutside = (event) => {
      const element = document.getElementById('database-popup');
      if (element && !element.contains(event.target)) {
        setIsSelectingDb(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
  }, []);

  const onTitleInput = (event) => {
    setTitle(event.target.value);
  };

  const renderAllDatabases = () => {
    return allDatabases.map((database) => {
      const isSelected = selectedDatabases.some(db => db.id === database.id);
      return (
        <div
          key={database.id}
          className={`h-8 w-full pl-4 flex items-center hover:bg-layer2 ${isSelected ? 'bg-layer2' : ''}`}
          onClick={() => toggleDatabase(database)}
        >
          <span className="text-sm">{database.name}</span>
        </div>
      );
    });
  };
  const toggleDatabase = (database) => {
    setSelectedDatabases(prev => {
      const isSelected = prev.some(db => db.id === database.id);
      if (isSelected) {
        return prev.filter(db => db.id !== database.id);
      } else {
        return [...prev, database];
      }
    });
  };

  const handleStartConversation = () => {
    if (selectedDatabases.length === 0) {
      Swal.fire('Error', "Database can't be empty", 'error');
      return;
    }

    if (!title) {
      Swal.fire('Error', "Title can't be empty", 'error');
      return;
    }
    const createConversationDto: CreateConversationDto = {
      conversationName: title,
      conversationType: ConversationType.TEXT2SQL,
      text2sql: { databaseIds: selectedDatabases.map(db => db.id) }
    };
    console.log('Creating conversation with:');
    console.log(createConversationDto);

    startNewConversation(createConversationDto)
      .then(convId => {
        fetchConversationWithId(
          convId,
          3,
          300,
          (conv) => {
            setCurConversation(conv);
            setFeatureOption(FeatureOption.NONE);
            setExpand(false);
          },
          (err) => {
            Swal.fire('Error', JSON.stringify(err), 'error');
          }
        );
      })
      .catch(err => {
        Swal.fire('Error', JSON.stringify(err.data));
      });
  };

  return (
    <div className="flex-1 flex m-auto h-full text-text_unselected">
      <div className="flex flex-col h-auto w-full self-center gap-3">
        <div className="flex items-center m-auto gap-3 my-6">
          <div className="w-12 h-12 bg-conversationalbi bg-cover" />
          <div className="flex flex-col gap-1">
            <span className="text-2xl font-bold text-title_conversationalbi">
              Ask Data
            </span>
            <span className="text-text_unselected">
              Create tailored responses based on your database
            </span>
          </div>
        </div>

        <div>
          <span className="pl-1 font-medium text-sm">Title</span>
          <input
            className="mt-2 h-12 w-full rounded border-solid border-outline_unselected border bg-transparent pl-4 text-sm font-normal outline-none text-text_selected flex items-center cursor-pointer"
            placeholder="Enter conversation title"
            value={title}
            onChange={onTitleInput}
          />
        </div>

        <div className="relative">
          {allDatabases.length === 0 ? (
            <div
              className="flex gap-1 items-center justify-center h-10 w-44 bg-active rounded cursor-pointer"
              onClick={() => {
                setAddDatabaseModal(true);
              }}
            >
              <div className="w-6 h-6 bg-blueplus" />
              <span className=" text-text_selected text-sm text-semibold">
                Add New Database
              </span>
            </div>
          ) : (
            <>
              <span className="pl-1 font-medium text-sm">My Database</span>
              <div
                className="mt-2 h-12 rounded border-solid border-outline_unselected border bg-transparent pl-4 text-sm font-normal outline-none text-text_unselected flex items-center cursor-pointer"
                onClick={() => setIsSelectingDb(!isSelectingDb)}
              >
                <span className={`${selectedDatabases.length > 0 && 'text-text_unselected'}`}>
                  {selectedDatabases.length > 0
                    ? `${selectedDatabases.length} database${selectedDatabases.length > 1 ? 's' : ''} selected`
                    : 'Select Databases'}
                </span>
                {isSelectingDb && (
                  <div
                    className="absolute right-2 top-0 p-2 bg-lightclose cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsSelectingDb(false);
                    }}
                  />
                )}
              </div>
              {isSelectingDb && (
                <div className="absolute w-full h-auto max-h-36 bg-active_hover rounded">
                  <div className="py-2 max-h-36 overflow-auto">
                    {renderAllDatabases()}
                  </div>
                </div>
              )}
            </>
          )}
        </div>

        <div className="bg-layer2 h-36 rounded flex flex-col overflow-auto gap-y-1">
          {selectedDatabases.length > 0 ? (
            selectedDatabases.map(database => (
              <div
                key={database.id}
                className="flex gap-x-6 p-7 justify-between items-center w-full h-12 rounded hover:bg-active text-text_unselected hover:text-text_selected bg-outline_unselected"
              >
                <p className="w-1/2 text-text_selected font-bold">
                  {database.name}
                </p>
                <div className="w-1/3">
                  <div className="w-24 truncate">{database.createdOn}</div>
                </div>
                <p className="w-1/6">{database.type}</p>
                <div onClick={() => toggleDatabase(database)}>
                  <div className="w-6 h-6 dark:bg-close bg-lightclose cursor-pointer" />
                </div>
              </div>
            ))
          ) : (
            <span className="text-text_unselected m-auto">No databases chosen</span>
          )}
        </div>

        <div className={'mt-2 h-10 w-full flex justify-end'}>
          <div className="flex items-center">
            <div
              className="ml-6 w-20 h-full flex items-center justify-center border border-outline_unselected rounded cursor-pointer"
              onClick={() => setOption(FeatureOption.NONE)}
            >
              <span className="font-semibold">Close</span>
            </div>
            <div
              className="ml-6 w-40 h-full flex items-center justify-center border border-layer2 rounded bg-layer2 cursor-pointer"
              onClick={handleStartConversation}
            >
              <span className="text-text_unselected font-semibold">
                Start Conversation
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
